@import '@stream-io/video-react-sdk/dist/css/styles.css';

.str-video {
  background-color: #272a30;
  color: #ffffff;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
  max-width: 100%;
}